@import '~antd/dist/antd.css';
@import 'assets/fonts/fonts.css';
@import 'variables.css';
@import 'style.css';
@import 'responsive.css';
@import 'ag-grid-custom.css';
@import url("https://cdn.syncfusion.com/ej2/bootstrap5.css");


/* media query starts here */

@media(max-width: 767px) {
    .category-list Search {
        margin: 10px;
    }

    .table {
        overflow: auto !important;
        /* color: red; */
    }

    .shadow {
        margin: 0px 0px 10px 0px;
        padding-right: 130px;
    }

    .btn-approve,
    .page-title-right {
        display: inline-flex;
        margin-top: 20px;
        margin-bottom: 10px;
        position: relative;
        right: 100px;
        align-items: center;

    }

    .shadow {
        padding-right: 30px !important;
    }


}

.e-pivotview .e-cellselectionbackground,
.e-pivotview .e-selectionbackground,
.e-pivotview .e-grid .e-rowsheader .e-selectionbackground,
.e-pivotview .e-grid .e-columnsheader.e-selectionbackground {
    background-color: greenYellow !important;
}


/* new design css */
body {
    overflow-y: scroll;
    color: #43425D;
    margin: 0;
    padding: 0;
}

.form-info {
    padding: 15px 90px 70px;
    background-color: #f5f5f5;
    margin-top: -15px;
}

.box-table label {
    display: block !important;
    margin: 8px 0px;
    font-weight: 500;
    font-size: 12px;

}

.box-table {
    background-color: #fff;
    box-shadow: rgba(255, 255, 255, 1) 0px 4px 4px 0px;
    padding: 30px;
    border-radius: 10px;
    margin: 20px 0px;
}

.box-table h2 {
    font-size: 22px;
    font-weight: 500;
}

.box-table input {
    padding: 4px;
    width: 200px;
    border: 1px solid #000;
    opacity: 75%;
}

.btn-submit button {
    background-color: #1890FF;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    font-size: 16px;
    margin-top: 10px;
}

.btn-cancel button {
    background-color: none;
    border: 1px solid gray;
    background: none;
    color: #1890FF;
    margin-left: 20px;
}

.modal-form input,
select {
    margin: 10px 5px;
    width: 250px;
}

.modal-form label {
    display: block;
    padding: 5px 0px;
}

h2 {
    margin: 0;
}

.site-card-border-less-wrapper {
    padding: 30px;
    background: #fbf7f7;
}




/* LoginScreen css Start */




.top-page-login {
    padding-bottom: 20px;
}

.login-page-form img {
    margin-left: 24px;
    width: 100px;
}

.login-page-form button.ant-btn.ant-btn-link {
    
    letter-spacing: 0.2px;
    font-weight: 500;
}

.login-page-form h1 {
    margin: 0;
}

/* SignUpScreen Css Start */

.signup-form-page form {
    padding-top: 35px;
}


/* Nav css Start */



.navbar-menu{
    margin: "15px";
    line-height:"30px";
    background-color: "transparent";
    float: "left";
    text-transform: "upperCase";
    width:"40%"
}

