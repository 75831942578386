.login-body1{
    width: 50%;
    float: left;

}
.main-div{
    background-color: rgb(238, 244, 244);
    padding-bottom:5%;
    
}
.main-div1{
    text-align: left;
    font-size: 200%;
    margin-left: 10%;
}
.login-body2{
    float: right;
    width: 50%;
   
}
.login-body{
    text-align: center;
margin-top: 0px;  
background-color:white;
padding: 20px;
width: 80%;
height: 450px;
margin-left: 10%;

}
.image{
   height: 10%;
   width: 10%;
   float:left;
   background-color: rgb(211, 25, 25);
}

input{
       padding: 7px 75px 7px 5px;
}

button
{
    background-color: rgb(253, 190, 75);
    border-color: aliceblue;
}

.btn,button{
     
      margin-left: 23%;
      padding: 5px 20px;
      color: white;
      cursor: pointer;
      font-weight: bold;
     
}
.sub{
      display: block;
      float: left;
      
}
.forg{
       display: block;
       float: left;
       margin-left: 25px;
       

}

.create
{ 
    text-align: left;
    margin:96px;
    margin-bottom: 0px;
    margin-top: 0px;

}
.approve{
           background-color: yellow;
}

.Tracks{
       background-color: rgb(29, 198, 142);
}
.create1
{
    text-align: left ;
    
}
.log{
   display: block;
   text-align: left ;
   margin-left: 150px;

}
.sign-in{
    text-align: left;
    margin-left: 142px;
    font-size: 300%;
    color: rgb(35, 112, 126);
}
p{
    font-size: 120%;
}
