.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bgimg {
  background-image: url(../src/images/hero-2.jpg);
  background-size: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  border-radius: 18px 0px 0px 18px;

}

.login-page-form {
  padding: 38px;
  margin: 0px;
}

h1.formtag6 {
  text-transform: capitalize;
  font-size: 26px;
  color: #688a34;
  margin-top: 80px !important;
}
.bgsine {
  box-shadow: 0 0 12px -2px #00000059;
}

.ant-form-item {
  text-align: left;
  margin: 0 0 14px !important;
}
.ant-row.ant-form-item-row {
  display: block;
}
.bgsine {
  border-radius: 18px;
  background-color: #fff;
}
.container.\30 1 {
  margin-top: 4%;
}


input#basic_email {
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
}

input#basic_name{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
}

input#basic_mobile{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
}

input#basic_address{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
}
input#companyname{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;

}
input#mobile{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
}
input#name{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
}
input#email{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
}
input#rc_select_1{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;

}
input#address{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
}
input#password{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
  border-right: 0px;
}
input#basic_password{
  border-radius: 0px;
  color: #688a34;
  border: 2px solid #e8bb48;
  padding: 10px 10px 10px 15px;
  outline: none !important;
  border-right: 0px;
}
span.ant-input-affix-wrapper.ant-input-password {
  padding: 0px;
}
svg {
  margin-right: 15px;
}
span.ant-input-suffix {
  margin: 0px;
  border-left: 0px !important;
  border: 2px solid #e8bb48;
}

.d-flex.but {
  margin-top: 21px;
}


button.ant-btn.ant-btn-primary.but01 {
  color: #fff;
  background-color: #688a34 !important;
  border-color: #688a34 !important;
}

button.ant-btn.ant-btn-secondary.but02:hover {
  background-color: #688a34 !important;
  border-color: #688a34 !important;
  color: #fff;
}
button.ant-btn.ant-btn-secondary {
  margin-left: 15px;
}


.but03 {
  text-align: left;
  margin-bottom: 80px;
}
button.ant-btn.ant-btn-link {
  padding: 0px;
  margin-top: 5px;
}
h2.wel {
  margin: 0px;
  padding-left: 6px;
}


.bgall {
  background-image: url(../src/images/grass.jpg);
  background-position: top;
  background-size: 100%;
  background-repeat: repeat;
  height: 100%;


}

.bgsine {
  box-shadow: 0 0 15px 11px #00000038;
}


h1.formtag6.\32{
  margin-top: 20px;
}


button.ant-btn.ant-btn-primary.but033 {
  margin-left: 26px;
  background-color: transparent !important;
  color: #8d8989;
  border: 1px solid #dcd8d8 !important;
}


button.ant-btn.ant-btn-primary.but033:hover {
  margin-left: 26px;
  background-color: #688a34 !important;
  color: #ffffff;
  border: 1px solid #dcd8d8 !important;
}

.text-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 97%;
  margin: 0 AUTO;
}
h5.fw-bold.text-secondary {
  margin: 0px;
}

h6.ret {
  text-transform: uppercase;
  font-weight: 700;
  margin: 0px 10px 0px 0px;
}


.col-md-4.raj h4 {
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
}

.mymodal{
  width: 100% !important;
  height: 100% !important;
}



.profile-menu { 
  .dropdown-menu{
    right: 0;
    left: unset;
  }
  .fa-fw{
    margin-right: 10px;
  }  
}
.toggle-change{
    &::after {
    border-top: 0;
    border-bottom: .3em solid;
    }
} 

nav.navbar.navbar-expand-lg.navbar-dark.bg-dark {
  background: #5b8c51 !important;
}

img.nav-user-icon {
  fill: #fff !important;
  mix-blend-mode: plus-lighter;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -50px !important;
  margin-top: var(--bs-dropdown-spacer);
}




a.navbar-brand {
    width: 10%;
    padding: 12px 0px 12px 0px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  transition: 0.5s;
}
.navbar-dark .navbar-nav .nav-link:hover{
  color: black;
}

a#navbarDropdown {
  font-size: 18px;
}


ul.dropdown-menu.show {
  border-radius: 0px;
  background: #5b8c51;
  border: 1px solid #fff;
  box-shadow: 0 0 7px 3px color(srgb 0 0 0 / 0.21);
}

a.dropdown-item:hover {
  background: #ffa71a;
  color: #000;
}
a.dropdown-item{
  transition: 0.5s;
  color: #ffffff;
}

li.nav-item.dropdown {
  margin-left: 9px;
}

a#navbarDropdown\ 02::after{
  display: none ;
}

h2.hemucon {
  color: #fff;
  /* text-transform: uppercase; */
  font-size: 16px;
  margin-right: 3px;
  color: #fff;
  transition: 0.5s;
}
h2.hemucon:hover{
  color: #ffa71a;
}

.d-flex.hemuicon {
  justify-content: end;
}

svg.user01 {
  fill: #fff;
  width: 40%;
  margin: 0px;
}
span.ant-input-suffix {
  background: #e8f0fe;
}
.col-md-4.text {
  text-align: left;
}

.text-center.main-heading h1 {
  text-transform: uppercase;
  font-size: 22px;
  margin: 0px;
  padding: 10px 0px 10px 0px;
  background: #ffa71a;
  color: #fff;
  

}

.row.mb-3.end {
  align-items: flex-end;
}

label.my {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
  color: #5b8c51;
}


input.ant-input {
  border-radius: 0px;
  padding: 7px;
  margin-top: 5px;
  border: 1px solid #ffa71a;
}

button.ant-btn.ant-btn-default.btnmy {
  margin-top: 7px;
  background: #ffa71a;
  border: 0px;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 35%);
  color: #fff;
  text-transform: uppercase;
  
}

button.ant-btn.ant-btn-primary.\30 2.btnmy {
  background: #ffa71a !important;
  box-shadow: 0 0 black !important;
  margin-top: 10px;
  color: #fff !important;
  border: 0px !important;
  font-size: 14px !important;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 35%) !important;
}




.table{
  width: 1250px !important;
}


input#basic_password{
  margin: 0px;
}

input#password {
  margin: 0px;
}

.table.rout {
  border: 0px;
}

.ant-form-item.raj0101 {
  margin-bottom: 0px !important;
}

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 18px !important;
} */
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #ffa71a !important;
  border-radius: 2px;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  border-radius: 0px;
  padding: 7px;
  margin-top: 5px;
}

.ant-table-wrapper {
  margin-top: 25px !important;
}

.ant-select.w01.ant-select-single.ant-select-show-arrow {
  width: 100% !important;
}
.row.mb-3.start {
  align-items: flex-start;
}

button.btnmy033{
  margin-top: 7px;
  background: #ffa71a;
  border: 0px;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 35%);
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
}




span.anticon.anticon-plus-square.btnmy.addraj svg {
  width: 30px;
  height: 30px;
  margin-top: 6px;
  margin-left: 10px;
  fill: green;
}

.ant-select-selector {
  min-width: 220px;
}

span.anticon.anticon-delete.btnmy.addraj02 svg {
  width: 25px;
  height: 25px;
  margin-top: 8px;
  margin-left: 0px;
  fill: red;
  margin-right: 0px !important;
}

.start {
  text-align: center;
  margin-top: 20px;
}


.d-flex01 {
  display: flex;
  width: 100% !important;
  justify-content: space-evenly;
  align-items: center;
}


.text-center.main-heading.bgcolor {
  background: #ffa71a;
}

button.btnmy02 {
  font-size: 13px;
  font-weight: 500;
  border: 1px solid transparent;
  color: #000000;
  background: #ffffff;
  padding: 5px 19px;
 border-radius: 15px;
  transition: 0.5s;
}

button.btnmy02:hover{
  background: #5b8c51;
  border: 1px solid #fff;
  color: #fff;

}

.col-md-2.text{
  text-align: left;
}

.d-flex.center {
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  justify-content: space-between;
}

input.ant-radio-input {
  width: 100%;
}

h6.rajj {
  margin: 0px 0px 0px 20px;
    font-size: 14px;
    color: rgba(62, 41, 155, 0.752);
    font-weight: 500;
    cursor: pointer;
}

h6.rajj:hover{
  color: gray;
}




page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}
page[size="A4"] {  
  width: 21cm;
  height: 29.7cm; 
}
page[size="A4"][layout="landscape"] {
  width: 29.7cm;
  height: 21cm;  
}
page[size="A3"] {
  width: 29.7cm;
  height: 42cm;
}
page[size="A3"][layout="landscape"] {
  width: 42cm;
  height: 29.7cm;  
}
page[size="A5"] {
  width: 14.8cm;
  height: 21cm;
}
page[size="A5"][layout="landscape"] {
  width: 21cm;
  height: 14.8cm;  
}
@media print {
  body, page {
    background: white;
    margin: 0;
    box-shadow: 0;
  }
}

button.ant-btn.ant-btn-primary.\30 2.btnmyret {
 
 float:right;
 margin-top: 7px;
 margin-right: 130px;
 background: #ffa71a;
 border: 0px;
 box-shadow: 0 0 5px 1px rgb(0 0 0 / 35%);
 color: #fff;
 text-transform: uppercase;

}


button.btnmyraj.btn.btn-primary {
  margin-top: 7px;
  background: #ffa71a;
  border: 0px;
  border-radius: 0px;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 35%);
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

button.btnmyraj01.btn.btn-primary {
  margin-left: 2%;
  margin-top: 7px;
  background: #ffa71a;
  font-size: 14px;
  font-weight: 500;
  border: 0px;
  border-radius: 0px;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 35%);
  color: #fff;
  text-transform: uppercase;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
  pointer-events: auto;
  width: 98%;
  margin: 0 auto;
}


.gradient-custom {
  background: #f6d365;
  background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));
  background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));
}

path.ph {
  color: #3b71ca;
  fill: #3b71ca;
}


.col-md-4.gradient-custom.text-center.text-white.hkborder {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}


.ant-picker.\30 1pik {
  width: 100% !important;
}

.ant-space.ant-space-vertical {
  width: 100%;
}

.rajho {
  width: 100%;
}

.Adda{
  margin: 0px;
}


header {
  border-bottom: 1px solid #eee;
  height: 4rem;
}
.burger-wrapper {
cursor: pointer;
}


.rajkamal02 {
 padding: 5px;
  /* border: 2px solid #ffa71a; */
}


svg.rajakamalsvg {
  margin: 0px;
  fill: #ffa71a;

}


.myminimenu {
  padding: 100px;
}
#meny {
  background: rgb(91 140 81 / 90%);

  width: 75%;
  height: 100vh;
  position: fixed;
  right: 0;
  transition-timing-function: cubic-bezier(10,2,3,1);
  transform: translateX(50rem);
  z-index: 999;
  transition: 0.5s;
}

.hamburger-react{
  color: rgb(255, 167, 26) !important;
  border: 1px solid #ffa71a !important;
}
#menyAvPaa {
  display: none;
}

#menyAvPaa:checked ~ #meny {
  transform: translateX(0rem);
}

#burger {
  position: absolute;
  cursor: pointer;
 
  /* height: 2rem; */
  right: 1rem;
  top: 5%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

a#navbarDropdown {
  color: #fff;
}

#burger > div {
 
  transition: 0.5s;
  z-index: 1000;
}

#menyAvPaa:checked ~ #burger > div {
background-color: #fff;
}

#menyAvPaa:checked ~ #burger > div:nth-child(1) {
  transform: translateY(15px) rotate(45deg);
}
#menyAvPaa:checked ~ #burger > div:nth-child(2) {
  opacity: 0;
}
#menyAvPaa:checked ~ #burger > div:nth-child(3) {
  transform: translateY(-15px) rotate(-45deg);
}

div#burger {
  margin-right: -16px;
}

.bgsine {
  margin-bottom: 250px;
}

button.ant-btn.ant-btn-default.btnmy4 {
  background-color: #109c58;
  color: #fff;
  box-shadow: 0 0 7px 0px #0000005e;
  border: 0px;
  margin-top: 25px;
}

svg.veryfied {
  fill: #FFF;
  margin-right: 6px;
}
/* .ant-modal.offer {
  height: 100vh !important;
  width: 90vw !important;
} */
footer.ant-layout-footer.footerH {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color:#e1e1e2;
  color: grey;
  text-decoration: solid;
  text-align: right;
}
h2.hemucon1 {
  color: red;
}
a.dropdown-item1 {
  color: red;
}


li.bell {
  list-style: none;
}
.bellul{
  padding: 0px;
}
.bellsvg{
  fill: #ff0000;
  margin-left: 2px;
  
}


header.header.header01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.messege01{
  font-size: 20px;
  text-transform: capitalize;
}

button.ant-btn.ant-btn-default.Alert.my {
  background: #dc3545;
  color: #fff;
  font-weight: 600;
}
.mainsvgm{
  height: 30px !important;
  fill: #ffa71a;
}
h2.messege01 {
  font-size: 16px;
  margin: 0px;
}


a.dropdown-item.\30 2 {
  color: #f00;
  font-size: 18px;
}
.card01{
  width: 30%;

}
.d-flex.hemuraj {
  justify-content: space-evenly;
  align-items: center;
}

.card01 {
  width: 30%;
  
}
svg.email01 {
  fill: #ffa71a;
  height: 70px;
  margin: 43px 0px 20px 0px;
}
.contact-info-text h2 {
  color: #fff;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 10px;
}
.contact-info-text span {
  color: #999999;
  font-size: 16px;
  display: inline-block;
  margin-top: 5px;
  width: 100%;
  margin-bottom: 37px;
}
.card-body.support {
  padding: 20px;
  background-color: #071c34;
  box-shadow: 5px 1px 9px 0px #00000094;
  margin-top: 14px;
}
h3.Support {
  color: #ffa71a;
}

a.dropdown-item.\30 2.black077 {
  color: #fff;
}
svg.black01 {
  fill: #fff;
}










.get_form_inner {
  display: block;
  padding: 50px 40px;
  background: #fff;
  box-shadow: -4px -2px 20px -7px #cfd5df;
}
input[type="text"], input[type="text"], input[type="email"], input[type="tel"] {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  height: 42px;
  padding: 0 0 0 20px;
  width: 100%;
  outline: 0;
}
.grid-50-50 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.grid-full {
  margin: 20px 0;
}
textarea {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  color: #333;
  padding: 12px 0 0 20px;
  width: 100%;
  outline: 0;
  margin-bottom: 20px;
}
.get_form_inner_text h3 {
  color: #333;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 40px;
}
input[type="submit"] {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid;
  font-weight: 500;
  padding: 10px 20px;
  outline: 0;
  cursor: pointer;
  color: #103e65;
  transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -moz-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -ms-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
input[type="submit"]:hover {
  background-color: #f85508;
  border-color: #f85508;
  color: #fff;
}
.get_say_form {
  display: inline-block;
  padding: 45px 0 25px 30px;
  background: #103e65;
  position: relative;
}
.get_say_form h5 {
  color: #fff;
  font-size: 26px;
  margin: 0 0 40px;
}
ul {
  list-style: none;
 
}

.get_say_social-icn a {
  font-size: 22px;
  color: #fff;
  padding: 0 20px 0 0;
}
.get_say_info_sec i {
  color: #fff;
  font-size: 32px;
}


.get_say_info_sec > li a:hover {
  background-color: #f85508;
}

svg.bellsvg1 {
  fill: #fff;
  margin-left: 11px;
}
.ant-form-item.rajbottom {
  margin: 0px !important;

}
.rajbottom02{
  margin-left: 10px;
}

label.col-md-2.Company {
  font-weight: bold;
}