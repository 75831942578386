@media(max-width: 991px) {

    /* loginscreen */


   

    /* memberAddScreen css Start */

    .page-title {
        font-size: 1rem !important;
    }

    .member-list-screen.btn-tck {
        padding: 6px 10px;
        margin-left: 20px;
    }

    /* Tabel css */
    .member-list-screen .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th,
    .ant-table tfoot>tr>td,
    .ant-table tfoot>tr>th {
        padding: 10px !important;
    }





}

@media(max-width: 767px) {

    .col-md-4.gradient-custom.text-center.text-white.hkborder {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0rem;
      }
      .card.mb-3.shadow.p-3.mb-5.bg-body.rounded {
        padding: 0px !important;
    }
    :root {
        --base-font-size: 13px;
    }
    .bgsine {
        margin-bottom: 70px;
      }

    .container {
        padding: 10px !important;
    }

    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
    .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
        padding: 0 10px;
    }

    .ant-layout-header.header {
        padding: 0 10px !important;
    }

    /* loginscreen */
    .logo {

        margin: 0px 5px 0px 0px !important;
    }

  

    /* memberListScreen css Start */


   .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th,
    .ant-table tfoot>tr>td,
    .ant-table tfoot>tr>th {
        padding: 8px !important;
    }
     /* memberAddScreen css Start */
     


}


@media(max-width: 575px) {

.col-md-4.hemop h6 {
    font-size: 18px;
    text-align: center;
}


.col-md-4.raj h4 {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
}

.col-md-4.d-flex.align-items-center.text-center {
    justify-content: center;
}












    span.anticon.anticon-delete {
        margin-right: 10px;
    }

    .anticon svg {
        display: inline-block;
        font-size: 10px;
        margin-left: 7px;
    }

    a.navbar-brand {
        width: 24%;
        
      }



    /* signupscreen */

    .ant-form-item .ant-form-item-label {
        padding: 0 0 0px !important;
    }
    .signup-form-page form {
        padding-top: 0px;
    }
    .signup-form-page .page-title {
        font-size: 1.2rem;
    }
    .signup-form-page form {
        padding-top: 0px;
    }
    .signup-form-page .ant-form-item {
        margin: 0 0 15px 10px !important;
    }

    /* nav css Start */
    ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
        width: 22% !important;
       }

    /* member css Start */  
    .ant-table-thead>tr>th {
        text-align: center !important;
    }
    .ant-table {
        font-size: 10px !important;
    }
     
    /* MemberListScreen css Start */
    .member-list-screen .ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th{
        text-align: left;
        padding: 3px !important;
    }
    /* .ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th{
        text-align: left !important;
    } */
    span.anticon.anticon-delete {
        margin-left: 0px !important;
    }
    span.anticon.anticon-edit {
        margin-left: 0px !important;
    }
    /* MemberAddScreen css Start */
   .member-add-screen .ant-form-item {
        margin: 0 0 15px !important;
    }
   .member-add-screen form{
    margin: 0px 0 0 25px !important;
   }
    /* MemberEditScreen css Start */
    .member-edit-screen form{
        margin: 0px 0 0 35px;
    }
    .ant-form-item {
        margin: 0 0 15px !important;
    }

    /* CustomerListScreen css Start */
    .ant-table-wrapper {
        margin-top: -25px;
    }
    .btn-tck{
        padding: 6px 8px;
    }
    .customber-list-screen-data{
        margin-top: 25px;
    }
    /* CustomerAddScreen css Start */
    .customber-add-screen form{
        margin: 0px 10px 0px !important;
    }
    .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th,
    .ant-table tfoot>tr>td,
    .ant-table tfoot>tr>th {
        padding: 3px !important;
    }
    .customber-list-screen .ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th{
        text-align: left;
        padding: 3px !important;
    }



    /* CustomerEditScreen css Start */

    .customber-edit-screen .ant-form-item{
        margin: 0 0 15px !important;
    }
    .customber-edit-screen form{
        margin: 0px 10px 0;
    }

    /* PickupListScreen css Start */

    .go-btn{
        margin-top: 30px;
    }


    .ant-form-item .ant-form-item-label>label {
        margin-left: 10px !important;
    }

    /* PickupAddScreen css Start */
    .pickp-add-screen form{
        margin: 0 10px;
    }

    /* FatsnFRateMatrix css start */
    .masters-rate-matrix span.page-title-right {
        display: none;
    }


    /* fat-sereen css Start */

    .fat-sereen .ant-table table {
        margin-top: 20px;
    }
   .fat-sereen button.ant-btn.ant-btn-primary {
        margin: 33px 0 0 20px;
    }
    .fat-sereen .ant-form-item {
        margin-left: 20px;
    }
    .fat-sereen form{
        margin-left: 15px;
    }
    .fat-sereen .ant-table table{
        text-align: center !important;
    }
    .ant-table table{
        text-align: left !important;
    }
    .fat-sereen .ant-btn-primary {
        min-width: 50px !important;
    }
    tr.ant-table-row.ant-table-row-level-0 {
        display: flex;
        justify-content: space-around;
    }

    /* master-snf-screen css Start */

    .master-snf-screen .ant-table table {
        margin-top: 20px;
    }
   .master-snf-screen button.ant-btn.ant-btn-primary {
        margin: 33px 0 0 20px;
    }
    .master-snf-screen form {
        margin-left: 15px;
    }

    .master-role-screen .ant-table table{
        text-align: center !important;
    }
    .master-role-screen .ant-btn-primary {
        min-width: 50px !important;
    }
    .master-snf-screen .ant-table table{
        text-align: center !important;
    }
    /* master-role-screen css Start */
     span.page-title-right {
        display: none;
    }
    .master-role-screen .ant-table table {
        margin-top: 20px;
    }
   .master-role-screen button.ant-btn.ant-btn-primary {
        margin: 33px 0 0 20px;
    }
    .master-role-screen form {
        margin-left: 15px;
    }
    .master-role-screen .ant-table table{
        text-align: center !important;
    }
    .ant-table table{
        text-align: center !important;
    }
    .master-role-screen .ant-btn-primary {
        min-width: 50px !important;
    }
    .VehicleScreen .ant-form-item{
        margin: 0 0 15px !important;
    }
    .VehicleScreen form {
        margin: 0 10px !important;
    }
    .vehicle-screen-table{
        margin-top: 30px;
    }

   
    
    /* Vehicle Type css start */

    .vehicle-type-screen .ant-table table {
        margin-top: 20px;
    }
   .vehicle-type-screen button.ant-btn.ant-btn-primary {
        margin: 33px 0 0 20px;
    }
    .vehicle-type-screen form {
        margin-left: 15px;
    }
    .vehicle-type-screen .ant-table table{
        text-align: center !important;
    }
    .vehicle-type-screen .ant-btn-primary {
        min-width: 50px !important;
    }
   


    
    /* Route Screen css Start */

    span.page-title-right{
        display: none;
    }
    .route-screen .ant-table-content {
        margin-top: 40px;
    }

    /* user-role-screen css Start */
    .user-role-screen .ant-table table {
        margin-top: 20px;
    }
   .user-role-screen button.ant-btn.ant-btn-primary {
        margin: 33px 0 0 20px;
    }
    .user-role-screen form {
        margin-left: 15px;
    }
    .master-role-screen .ant-table table{
        text-align: center !important;
    }
    .user-role-screen .ant-btn-primary {
        min-width: 50px !important;
    }
    .user-role-screen .ant-table table{
        text-align: center !important;
    }

    /* default-user-setting css Start */

    .default-user-setting form{
        margin: 0 10px;
    }
    .default-user-setting .ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th{
        text-align: left;
        padding: 3px !important;
    }

    .default-user-setting .ant-form-item{
        margin: 0 0 15px !important;
    }
    .pickup-list-screen table {
        margin-top: 30px;
    }
    /* tr.ant-table-row.ant-table-row-level-0{
        flex: none;
    } */
    .default-user-setting tr.ant-table-row.ant-table-row-level-0 {
        display: flex;
        justify-content: space-between;
        align-items:center;
    }


    .pickup-list-screen .ant-btn-primary {
        min-width: 40px !important;
    }
    .pickup-list-screen .pic-btn-primary{
        margin-top: 30px;
    }
    .ant-btn {
        height: 30px;
        padding: 0px 0px;
    }
    .pickup-list-screen .icon-list-pic{
        margin-right: 1px;
    }
    .pickup-list-screen .ant-btn.ant-btn-primary {
        color: var(--btn-primary-color) !important;
        background: transparent !important;
        border: none!important;
    }
   .pickup-list-screen .ant-btn-primary {
        box-shadow: none !important;
    }
    .pickup-list-screen .ant-picker{
        margin-left: 4px;
    }
   
    .pickup-list-screen tr.ant-table-row.ant-table-row-level-0 {
        display: flex;
        justify-content: space-between;
        align-items:center;
    }
    .pickup-list-screen td.ant-table-cell {
        text-align: left;
    }


    /* today */

    thead.ant-table-thead {
        display: none;
    }

    /* thead.ant-table-thead {
        display: inline-block;
        width: 100%;
    } */
    /* th.ant-table-cell {
        width: 14%;
    } */

    .deafultuser-setting-data{
        margin-top: 40px;
    }
    .defaultuser-setting-data{
        margin-top: 40px !important;
    }
    .member-list-screen-data{
        margin-top: 30px;
    }





    /* ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
        flex-direction: column;
    } */


}



@media(max-width: 375px) {
   
    /* nav css Start */
   ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
    /* width: 20% !important; */
    width: 7% !important;
   }
}



/* my responsive */


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    h2.messege01 {
        font-size: 14px !important;
        margin: 0px;
    }
    button.ant-btn.ant-btn-default.Alert.my{
        display: none;
    }

    .bgimg {
     
        border-radius: 18px 18px 0px 0px;
    }
    .bgimg {
        margin-bottom: 245px;
    }
    h1.formtag6 {
      
        margin-top: 10px !important;
        font-size: 20px;
    }
    .but03 {
        text-align: left;
        margin-bottom: -13px;
    }
    .bgsine {
        box-shadow: 0 0 11px 0px #00000038;
    }
    .bgall {
     
        background-repeat: repeat;
      
      }
      .login-page-form {
        margin-top: 0%;
    }
    button.ant-btn.ant-btn-primary.but033 {
        margin-left: 0px !important;
        background-color: #688a34 !important;
        color: #ffffff;
        border: 1px solid #dcd8d8 !important;
      }
    }
    
    
    @media screen and (min-device-width: 600px) and (max-device-width: 992px) { 
        

        a.navbar-brand {
            width: 18%;
        }
    .bgimg {
     
        border-radius: 18px 18px 0px 18px;
    }
    .bgimg {
        margin-bottom: 245px;
    }
    h1.formtag6 {
      
        margin-top: -24px;
        font-size: 20px;
    }
    .but03 {
        text-align: left;
        margin-bottom: -13px;
    }
    .bgsine {
        box-shadow: 0 0 11px 0px #00000038;
    }
    .bgall {
     
        background-repeat: repeat;
      
      }
      .login-page-form {
        margin-top: 0%;
    }
    }



    @media screen and (min-width:992px){

        label#burger{
            display: none;
        }
        #meny{
            display: none;
        }
    }



    